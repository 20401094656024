import React, { CSSProperties, FC, useMemo } from "react"
import Styles from "../styles/UserAvatar.module.scss";
import { navigate } from "gatsby";
import getNewPathAvoidingCache from "../functions/getNewPathAvoidingCache"
import clsx from "clsx"
import { UserIcon } from "../icons/UserIcon";

type Props = {
    userName?: string,
    userID?: string,
    profileImageURL?: string,
    size?: number,
    avatarSize?: number,
    style?: CSSProperties,
    noTransition?: boolean
}

const UserAvatar: FC<Props> = ( {
    userName,
    userID,
    profileImageURL,
    size,
    avatarSize,
    style = {},
    noTransition = false
} ) =>
    useMemo( () =>
        userID ?
            <div className={ clsx( Styles.container, !profileImageURL && Styles.no_image ) } style={ style }>
                <span
                    style={ { display: "flex" } }
                    onClick={ () => {
                        !noTransition && userID && navigate( "/user_profile", { state: { userID, userName } } )
                    } }>
                    { profileImageURL ?
                        <img
                            onError={ ( e ) => getNewPathAvoidingCache( e.target as HTMLImageElement ) }
                            src={ profileImageURL }
                            style={ { width: size, height: size, minWidth: size, minHeight: size } }
                        />
                        :
                        <UserIcon
                            style={ { color: "#ccc", fontSize: avatarSize || 20, transform: "translate3d(0px, 0, 0)" } } /> }
                </span>
            </div>
            :
            <div className={ clsx( Styles.container, Styles.no_image ) } style={ style }>
                <span
                    style={ { display: "flex" } }
                    onClick={ () => { } }>
                    <UserIcon
                        style={ { color: "#ccc", fontSize: avatarSize || 20, transform: "translate3d(0px, 0, 0)" } } />
                </span>
            </div>
        , [ userID ] )

export default UserAvatar

