import React, { useState, useEffect, useContext } from "react"
import { navigate, Link } from "gatsby"
import Styles from "../styles/team_profile.module.scss";
import MediaQuery from "react-responsive";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import InfoItem from "../components/InfoItem"
import GeneralButton from "../components/atoms/GeneralButton";
import UserListItem from "../components/UserListItem";
import UserTeamJoinOrCreateTemplate from "../templates/UserTeamJoinOrCreateTemplate"
import { BACK_BUTTON_STR } from "../constant/const";
import { UserType } from "../types/models/User";
import { SportsGetType, SportsGetType__ } from "../types/models/Sports";
import client from "../apis/client";
import { addOrRemoveAdminMember, removeMember } from "../apis/team";
import { ToastContext } from "../context/ToastContext";
import { useSelector } from "react-redux";


const initialInfoList = [
    { name: "name", label: "チーム名", value: "" },
    { name: "idname", label: "チームID", value: "" },
    { name: "email", label: "メールアドレス", value: "" },
]

const TeamInfoPage = () => {

    const headerOption = {
        headerTitle: "チーム情報",
        leftIcon: BACK_BUTTON_STR,
        rightIcon:
            <span onClick={ () =>
                navigate( "/team_info_update", {
                    state: {
                        infoList,
                        teamSportsList,
                        sportsList,
                        imagePath
                    }
                } ) }>
                編集
            </span>,
    }

    const [ belongToTeam, setBelongToTeam ] = useState( true )
    const [ infoList, setInfoList ] = useState( initialInfoList )
    const [ teamSportsList, setTeamSportsList ] = useState<{ label: string }[]>( [] )
    const [ sportsList, setSportsList ] = useState<SportsGetType[]>( [] )
    const [ imagePath, setImagePath ] = useState( "" )
    const [ openProfile, setOpenProfile ] = useState( false );
    const [ memberList, setMemberList ] = useState<UserType[]>( [] )
    const { addToast } = useContext( ToastContext )
    const userUuid = useSelector( state => state.app.userUuid )

    useEffect( () => {
        getTeamData()
    }, [] )

    const getTeamData = async () => {
        try {
            const { data } = await client.get( `/api/community/me/`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
            setInfoList( infoList.map( info => ( {
                ...info, value: data[ info.name ]
            } ) ).slice() )
            const sports_list: SportsGetType__[] = data.sports_list
            setTeamSportsList(
                sports_list.filter( sports => sports.isSelected )
                    .map( sports => ( { label: sports.label } )
                    ).slice() )

            setBelongToTeam( true )
            setSportsList( data.sports_list )
            setMemberList( data.member )
            setImagePath( data.profile_image )
            setOpenProfile( data.open_profile )
        }
        catch ( error ) {
            console.log( error )
            if ( error.response.status === 400 ) {
                setBelongToTeam( false )
            }
        }
    }

    const addUserToAdmin = async ( userId: string ) => {
        try {
            const { data } = await addOrRemoveAdminMember( { userId: userId, removeOrAdd: "add" } )
            addToast( {
                text: "管理者に設定しました",
                type: "success",
                position: "center"
            } )
            getTeamData()
        }
        catch ( error ) {
            console.log( error )
            addToast( {
                text: error.response.data.detail,
                type: "error",
                position: "center"
            } )
        }
    }

    const removeUserFromAdmin = async ( userId: string ) => {
        if ( !window.confirm( "本当に管理者から外しますか？" ) ) return
        try {
            const { data } = await addOrRemoveAdminMember( { userId: userId, removeOrAdd: "remove" } )
            addToast( {
                text: "管理者から外しました",
                type: "success",
                position: "center"
            } )
            getTeamData()
        }
        catch ( error ) {
            console.log( error )
            addToast( {
                text: error.response.data.detail,
                type: "error",
                position: "center"
            } )
        }
    }

    const removeMemberFromTeam = async ( userId: string ) => {
        if ( !window.confirm( "本当に引退させますか？" ) ) return
        try {
            const { data } = await removeMember( { userId: userId } )
            addToast( {
                text: "引退させました",
                type: "success",
                position: "center"
            } )
            getTeamData()
        }
        catch ( error ) {
            console.log( error )
            addToast( {
                text: error.response.data.detail,
                type: "error",
                position: "center"
            } )
        }
    }


    if ( !belongToTeam ) {
        return (
            <Layout headerOption={ { ...headerOption, rightIcon: undefined } }>
                <>
                    <SEO title="TeamInfo" />
                    <UserTeamJoinOrCreateTemplate
                        belongToTeam={ false }
                        onSucceeded={ () => {
                            setBelongToTeam( !belongToTeam )
                            getTeamData()
                        } } />
                </>
            </Layout>
        )
    }

    const isAdminUser = memberList.find( member => member.pk === userUuid )?.is_admin_user

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="TeamInfo" />
                <div className={ Styles.container }>
                    <div className={ Styles.team_image_container }>
                        <img className={ Styles.team_image } src={ imagePath } />
                    </div>
                    <MediaQuery query="(min-width: 900px)">
                        <p className={ Styles.edit_button_container }>
                            <GeneralButton
                                style={ { marginBottom: 8, width: 120 } }
                                onClick={ () =>
                                    navigate( "/team_info_update", {
                                        state: {
                                            infoList,
                                            teamSportsList,
                                            sportsList,
                                            imagePath,
                                            openProfile
                                        }
                                    } ) }
                                title="編集" />
                        </p>
                    </MediaQuery>
                    <div className={ Styles.usage_link_wrapper }>
                        <Link to="/team_usage" style={ { fontWeight: "bold" } }>
                            ご利用状況はこちら
                        </Link>
                    </div>
                    <ul className={ Styles.info_list }>
                        { infoList.map( info =>
                            <InfoItem label={ info.label } key={ info.label } value={ info.value } />
                        ) }
                        <InfoItem
                            label="スポーツ"
                            value={ teamSportsList.map( sports =>
                                <span key={ JSON.stringify( sports.label ) }>{ sports.label }</span>
                            ) } />
                        <InfoItem
                            label="チーム情報の非公開"
                            key="open_profile"
                            value={ openProfile ? "公開" : "非公開" } />
                        <InfoItem
                            label="メンバー"
                            column={ true }
                            value={
                                memberList.map( member =>
                                    <UserListItem
                                        key={ member.name }
                                        user={ member }
                                        menuFunctions={ isAdminUser ? {
                                            removeUserFromAdmin,
                                            addUserToAdmin,
                                            removeMemberFromTeam
                                        } : undefined } />
                                )
                            }
                        />
                    </ul>
                </div>
            </>
        </Layout>
    )
}

export default TeamInfoPage
