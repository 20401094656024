import { AxiosPromise } from 'axios';
import client from './client';

type addOrRemoveAdminMemberResponse = {
    detail: string
}

type addOrRemoveAdminMemberParams = {
    userId: string,
    removeOrAdd: "remove" | "add"
}

export const addOrRemoveAdminMember = (
    { userId, removeOrAdd }: addOrRemoveAdminMemberParams ): AxiosPromise<addOrRemoveAdminMemberResponse> => {
    const data = new FormData()
    data.append( "user_uuid", userId )
    data.append( "remove_or_add", removeOrAdd )
    return client.post( `/api/team/me/admin_user/`, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    } )
}

type removeMemberResponse = {
    detail: string
}

type removeMemberParams = {
    userId: string,
}

export const removeMember = (
    { userId }: removeMemberParams ): AxiosPromise<removeMemberResponse> => {
    const data = new FormData()
    data.append( "user_uuid", userId )
    return client.post( `/api/team/me/member/`, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    } )
}
